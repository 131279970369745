.user-panel {
  @include standard-panel;
  display: flex;
  width: 265px;
  flex-direction: column;
  overflow: hidden;

  .user-panel__account__name {
    display: inline;
  }

  .verified-icon {
    opacity: 1;
  }

  &,
  .user-panel__account__name,
  .user-panel__account__username {
    white-space: nowrap;
    color: var(--primary-text-color--faint);
  }

  &__header {
    display: block;
    height: 112px;
    width: 100%;
    background: var(--brand-color--faint);

    .still-image {
      height: 100%;
    }

    img {
      display: block;
      height: 100%;
      width: 100%;
      margin: 0;
      object-fit: cover;
    }
  }

  &__profile {
    display: flex;
    align-items: flex-start;
    padding: 0 10px;
    margin-top: -53px;

    .account__avatar {
      display: block;
      width: 82px;
      height: 82px;
      border: 6px solid var(--foreground-color);
      background-size: cover;
    }
  }

  &__meta {
    display: block;
    padding: 6px 20px 17px;
    // opacity: 0.6;
  }

  &__account {
    a {
      text-decoration: none;
      color: var(--primary-text-color--faint);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__name {
      display: block;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      color: var(--primary-text-color--faint);
    }

    &:hover & {
      &__name {
        text-decoration: underline;
      }
    }

    &__username {
      display: block;
      font-size: 14px;
      line-height: 16px;
      color: var(--primary-text-color--faint);
      text-decoration: none !important;
    }
  }

  &__stats-block {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
  }

  .user-panel-stats-item {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    flex-wrap: wrap;
    flex-direction: column;

    a {
      text-decoration: none;
      color: var(--primary-text-color--faint);

      &:hover {
        opacity: 0.8;
      }
    }

    &__value {
      display: block;
      width: 100%;
      color: var(--primary-text-color--faint);
      font-size: 20px;
      font-weight: 800;
      line-height: 24px;
    }

    &__label {
      display: block;
      width: 100%;
      color: var(--primary-text-color--faint);
      font-size: 12px;
      line-height: 14px;
    }
  }
}
