.setting-toggle__text {
  display: flex;
  flex-direction: column;
}

.setting-toggle__label {
  color: var(--gray-800);
}

.setting-toggle__hint {
  color: var(--gray-500);
  font-size: 12px;
}
