.theme-toggle {
  .setting-toggle {
    &__label {
      margin-bottom: 0;
      vertical-align: middle;
    }

    .react-toggle {
      vertical-align: middle;

      &-track-check,
      &-track-x {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        width: auto;
      }

      &-track-check {
        left: 6px;
      }

      &-track-x {
        right: 5px;
      }
    }
  }

  .react-toggle-track {
    @apply dark:bg-slate-600;
  }

  .react-toggle-thumb {
    @apply dark:bg-slate-900 dark:border-slate-800;
  }
}
