.media-spoiler {
  background: var(--background-color);
  color: var(--primary-text-color--faint);
  border: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  appearance: none;

  &:hover,
  &:active,
  &:focus {
    padding: 0;
    color: var(--primary-text-color);
  }
}

.media-spoiler__warning {
  display: block;
  font-size: 14px;
}

.media-spoiler__trigger {
  display: block;
  font-size: 11px;
  font-weight: 700;
}
