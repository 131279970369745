.placeholder-hashtag,
.notification--placeholder,
.status-card--placeholder,
.media-gallery--placeholder {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      90deg,
      hsla(var(--foreground-color_hsl), 0) 0%,
      hsla(var(--foreground-color_hsl), 0) 25%,
      var(--foreground-color) 50%,
      hsla(var(--foreground-color_hsl), 0) 70%,
      hsla(var(--foreground-color_hsl), 0) 100%
    );
    background-size: 200%;
    animation: placeholder-pulse 2s infinite;
    z-index: 1;
    opacity: 0.9;
  }
}

@keyframes placeholder-pulse {
  0% { background-position-x: 200%; }
  100% { background-position-x: 0; }
}

.placeholder-hashtag .trends__item__name {
  color: var(--brand-color);
  opacity: 0.1;
}

.chat-list-item--placeholder .chat__last-message {
  letter-spacing: -1px;
  color: var(--brand-color) !important;
  opacity: 0.1;
}

.slist {
  position: relative;

  &__placeholder {
    position: absolute;
    width: 100%;
    max-height: 300px;
    overflow-y: hidden;
    background: var(--foreground-color);
    box-shadow: 0 10px 6px 0 rgb(0 0 0 / 10%);

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      z-index: 1;
      // On mobile, extend the gradient up to the ThumbNavigation height
      background-image: linear-gradient(
        0deg,
        var(--background-color) 0%,
        var(--background-color) var(--thumb-navigation-height),
        hsla(var(--foreground-color_hsl), 0) 50%,
        hsla(var(--foreground-color_hsl), 0) 100%
      );
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      @media (min-width: 895px) {
        // On desktop, fade into the bottom of the page
        background-image: linear-gradient(
          0deg,
          var(--background-color) 0%,
          hsla(var(--foreground-color_hsl), 0) 50%,
          hsla(var(--foreground-color_hsl), 0) 100%
        );
      }
    }
  }
}

.column--transparent .slist__placeholder {
  background: transparent;
  box-shadow: none;
}

.status-card--placeholder {
  pointer-events: none;

  .status-card__title,
  .status-card__description,
  .status-card__host {
    letter-spacing: -1px;
    color: var(--brand-color) !important;
    word-break: break-all;
    opacity: 0.1;
  }
}

.media-gallery.media-gallery--placeholder {
  background: none;

  .media-gallery__item {
    background-color: var(--brand-color--faint);
  }
}
