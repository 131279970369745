.dyslexic {
  font-family: 'OpenDyslexic' !important;
  margin-bottom: 8px;
}

body.dyslexic {

  @media screen and (max-width: $nav-breakpoint-2) {
    .column-header > button,
    .column-header > .btn {
      font-size: 11px;
    }
  }

  .detailed-status__meta {
    font-size: 12px;
  }
}
