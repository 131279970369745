.aliases {
  &__accounts {
    overflow-y: auto;

    .account__display-name {
      &:hover strong {
        text-decoration: none;
      }
    }

    .account__avatar {
      cursor: default;
    }

    &.empty-column-indicator {
      min-height: unset;
      overflow-y: unset;
    }
  }

  &_search.search {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: none;

    .search__input {
      padding: 7px 30px 6px 10px;
      background: none;
    }

    > label {
      flex: 1 1;
    }

    > .button {
      width: 80px;
      margin-left: 10px;
    }
  }
}

.aliases-settings-panel {
  flex: 1;

  .item-list article {
    border-bottom: 1px solid var(--primary-text-color--faint);

    &:last-child {
      border-bottom: 0;
    }
  }

  .slist--flex {
    height: 100%;
  }
}
