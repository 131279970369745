.list-editor {
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: 100%;
  overflow-y: auto;

  h4 {
    padding: 15px 0;
    background: var(--background-color);
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    border-radius: 8px 8px 0 0;
  }

  &__content {
    padding: 0;
  }

  &__accounts {
    background: var(--background-color);
    overflow-y: auto;
    max-height: 200px;
  }

  .account__display-name {
    &:hover strong {
      text-decoration: none;
    }
  }

  .account__avatar {
    cursor: default;
  }

  .search {
    display: flex;
    flex-direction: row;
    margin: 10px;

    > label {
      flex: 1 1;
    }

    > .search__icon {
      position: relative;
    }

    > .button {
      width: 80px;
      margin-left: 10px;
    }
  }
}

.list-adder {
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: 100%;
  overflow-y: auto;

  &__account {
    background: var(--background-color);
    border-radius: 4px;
  }

  &__lists {
    background: var(--background-color);
  }

  .list {
    padding: 4px;
    border-bottom: 1px solid var(--brand-color--med);
  }

  .list__wrapper {
    display: flex;

    .account__relationship {
      padding: 8px 5px 0;
    }
  }

  .list__display-name {
    flex: 1 1 auto;
    overflow: hidden;
    text-decoration: none;
    font-size: 16px;
    padding: 10px;
  }
}

.new-list-form,
.edit-list-form {
  &__btn {
    margin-left: 6px;
    width: 112px;
  }

  &__input {
    height: 36px;
  }
}
