.error-boundary {
  box-sizing: border-box;
  min-height: 100vh;
  max-width: 375px;
  text-align: center;
  padding: 10px 10px 80px;

  * {
    box-sizing: border-box;
  }

  ::selection {
    background-color: #000;
    color: #fff;
  }

  .sad-face {
    width: 70px;
    height: 70px;
    margin: 20px auto;
    opacity: 0.5;
    transition: 0.8s;

    svg {
      stroke-width: 1.5px;
    }

    &:hover {
      transform: rotate(180deg);
    }
  }

  a {
    color: var(--brand-color);
  }

  > div {
    margin: auto;

    span {
      display: block;
      text-align: center;
      color: var(--primary-text-color--faint);
    }

    .return-home {
      display: flex;
      margin: 15px auto;
      justify-content: center;
      align-items: center;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .svg-icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
  }

  p.help-text {
    text-align: left;
    font-style: italic;
    font-size: 14px;
    padding: 20px 10px 0;
    margin-top: 20px;
    opacity: 0.7;
    border-top: 1px solid;
  }

  &__component-stack {
    width: 100%;
    height: 200px;
    text-align: left;
    margin: 20px 0;
    padding: 10px;
  }
}
