.primary-navigation__wrapper {
  .primary-navigation {
    flex-direction: column;

    &:hover .btn.grouped:hover::before {
      height: auto;
      opacity: 0 !important;
    }

    > button,
    > .btn.grouped {
      justify-content: flex-start;
      font-weight: 700;
      font-size: 15px;
      margin: 0;
      padding: 10px 0;
      color: var(--gray-400);

      .svg-icon {
        height: 22px;
        width: 22px;
        padding: 10px;
        margin-right: 15px;
        background-color: var(--electric-blue-contrast);
        border-radius: var(--border-radius-lg);

        svg {
          stroke: var(--electric-blue);
          fill: var(--electric-blue-contrast);
        }

        svg.icon-tabler {
          stroke-width: 1.5px;
        }

        &--active {
          svg.icon-tabler-search,
          svg.icon-tabler-code {
            stroke-width: 2.5px;
          }
        }
      }

      &.active {
        color: var(--black-800);

        &::before {
          height: auto;
          opacity: 0;
        }

        .svg-icon {
          background-color: var(--electric-blue);
        }

        .svg-icon svg {
          stroke: #fff;
          fill: var(--electric-blue);
        }
      }

      &::before {
        left: 0;
        border-radius: 999px;
      }
    }

    &__icon {
      margin-right: 5px;
    }

    .icon-with-counter__counter {
      right: 0;
    }

    hr {
      border: 0;
      height: 1px;
      width: 100%;
      background: hsla(var(--primary-text-color_hsl), 0.12);
      max-width: 230px;
      text-align: left;
      margin: 10px 0;
    }
  }
}

.thumb-navigation {
  @apply fixed lg:hidden bottom-0 bg-white dark:bg-slate-800 border-t border-solid border-gray-200 dark:border-gray-700 left-0 right-0 shadow-2xl w-full flex z-50;
  padding-bottom: env(safe-area-inset-bottom); /* iOS PWA */
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #fff;

  &::-webkit-scrollbar {
    display: none;
  }

  &__link {
    @apply px-2 py-2.5 space-y-1 flex flex-col flex-1 items-center text-gray-600 text-lg;
    // padding: 8px 10px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: end;
    // color: var(--primary-text-color);
    // text-decoration: none;
    // font-size: 20px;
    // width: 55px;

    // span {
    //   margin-top: 1px;
    //   text-align: center;
    //   font-size: 1.2rem;
    // }

    // .svg-icon {
    //   width: 24px;
    //   height: 24px;

    //   svg {
    //     stroke-width: 1px;
    //   }
    // }

    .icon-with-counter__counter {
      right: -7px;
      top: -5px;
    }
  }
}

.sub-navigation {
  position: sticky;
  top: 50px;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
  display: flex;
  color: var(--primary-text-color--faint);
  background: var(--foreground-color);
  border-bottom: 1px solid hsla(var(--primary-text-color_hsl), 0.2);
  border-radius: 0;
  align-items: center;
  justify-content: center;
  z-index: 999;

  &--scrolled {
    border-radius: 0 !important;
  }

  &__content {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    padding: 5px 15px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
  }

  &__back {
    margin-right: auto;
    padding: 0;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-text-color);
    opacity: 0.6;
    font-size: 16px;

    &:hover,
    &:focus {
      color: var(--primary-text-color);
    }

    .svg-icon {
      margin-right: 7px;
      width: 26px;
      height: 26px;
    }
  }

  &__message {
    position: absolute;
    padding: 0 10px;
    align-self: center;
    justify-self: center;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100vw - 200px);
  }

  &__cog {
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      width: 20px;
      height: 20px;
    }
  }

  @media (min-width: 580px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
