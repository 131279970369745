.promo-panel {
  margin-top: 10px;
  background-color: transparent !important;
  overflow: hidden;
}

.promo-panel-item {
  @include standard-panel-shadow;
  display: flex;
  align-items: center;
  height: 42px;
  line-height: 42px;
  color: var(--primary-text-color);
  border-bottom: 1px solid var(--brand-color--med);
  background: var(--foreground-color);
  text-decoration: none;
  font-size: 15px;
  padding: 0 20px;

  &:last-of-type {
    border-bottom: 0;
  }

  &:hover,
  &:focus {
    color: var(--primary-text-color--faint);

    span {
      text-decoration: underline;
    }
  }

  i.fa {
    font-size: 22px;
    margin-right: 10px;
  }

  .svg-icon {
    width: 24px;
    height: 24px;

    svg {
      stroke-width: 1.3px;
    }
  }

  > .svg-icon,
  > .icon-with-counter {
    margin-right: 12px;
  }
}
