.federation-restrictions {
  padding-top: 15px;

  .slist .item-list > article {
    padding: 0 20px;

    &:last-child {
      padding-bottom: 15px;
    }
  }
}

.restricted-instance {
  &__header {
    padding: 10px 0;
    display: flex;
    text-decoration: none;
    color: var(--primary-text-color);
  }

  &__icon {
    width: 16px;

    .svg-icon svg {
      stroke-width: 1.3;
    }
  }

  &--expanded &__icon i.fa {
    transform: translateX(-3px);
  }

  &--reject &__host {
    text-decoration: line-through;
  }

  &__restrictions {
    height: 0;
    overflow: hidden;
  }

  &--expanded &__restrictions {
    height: auto;
  }

  .instance-restrictions {
    padding: 5px 0 5px 15px;
    border-left: 3px solid hsla(var(--primary-text-color_hsl), 0.4);
    color: var(--primary-text-color--faint);
    margin-bottom: 15px;

    .federation-restriction {
      padding: 7px 0;
      font-size: 14px;
    }

    &__message {
      margin-bottom: 10px;

      i.fa {
        padding-right: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.edit-federation-modal {
  background: var(--foreground-color);
  border-radius: 8px;
  padding: 20px;

  &__title {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
    text-align: center;
  }

  &__submit {
    margin-bottom: 0 !important;
    margin-top: 20px;
  }
}
